// Volume and weight conversion constants
export const g_to_kg = 0.001
export const ml_to_l = 0.001
export const cup_to_l = 0.236588
export const quart_to_l = 0.946353
export const gallons_to_l = 3.78541
export const floz_to_l = 0.0295735
export const tbsp_to_l = 0.0147868
export const tsp_to_l = 0.00492892
export const oz_to_g = 28.3495
export const lbs_to_g = 453.592