import React  from 'react';

import './Title.css'

export default function Title() {
    return (
        <div className="title">
            <h1 className="main-title">FlourGenius</h1>
            <br />
            <h2 className="subtitle">Convert between ingredent volume and weight</h2>
        </div>
    )
}