import './App.css';
import CalculatorForm from './components/forms/CalculatorForm';
import Title from './components/Title';
import React from 'react'

function App() {
  return (
    <div className="App">
      <Title />
      <CalculatorForm />
    </div>
  );
}

export default App;
