// Ingredients with g/L values
// Note: all densities assume (NIST) normal temperature and pressure (20 C @ 1 atm)
const ingredientOptions = [
    {
        'id': 1,
        'name': 'All-Purpose Flour', 
        'density': 528.34,
        'category': 'Flours'
    },
    {
        'id': 2,
        'name': 'Bread Flour', 
        'density': 536.80, 
        'category': 'Flours'
    },
    { 
        'id': 3,
        'name': '00 Flour', 
        'density': 536.80,
        'category': 'Flours'
    },
    { 
        'id': 4,
        'name': 'Whole Wheat Flour', 
        'density': 547.78,
        'category': 'Flours'
    },
    { 
        'id': 5,
        'name': 'Rye Flour', 
        'density': 431.13,
        'category': 'Flours'
    },
    { 
        'id': 6,
        'name': 'Gluten-Free Flour', 
        'density': 515.66,
        'category': 'Flours'
    },
    { 
        'id': 7,
        'name': 'Buckwheat Flour', 
        'density': 507.21,
        'category': 'Flours'
    },
    {
        'id': 8,
        'name': 'Tapioca Flour', 
        'density': 515.66,
        'category': 'Flours'
    },
    {
        'id': 9,
        'name': 'Coconut Flour',
        'density': 473.40,
        'category': 'Flours'
    },
    {
        'id': 10,
        'name': 'Water, tap',
        'density': 1002.60,
        'category': 'Liquids'
    },
    {
        'id': 11,
        'name': 'Water, distilled',
        'density': 998.20,
        'category': 'Liquids'
    },
    {
        'id': 12,
        'name': 'Milk',
        'density': 1030.00,
        'category': 'Liquids'
    },
    {
        'id': 13,
        'name': 'Buttermilk',
        'density': 1036.00,
        'category': 'Liquids'
    },
    {
        'id': 14,
        'name': 'Vanilla extract, natural',
        'density': 880.00,
        'category': 'Liquids'
    },
    {
        'id': 15,
        'name': 'Vanilla extract, imitation',
        'density': 879.00,
        'category': 'Liquids'
    },
    {
        'id': 16,
        'name': 'Rum',
        'density': 940.00,
        'category': 'Liquids'
    },
    {
        'id': 17,
        'name': 'White Vinegar',
        'density': 960.00,
        'category': 'Liquids'
    },
    {
        'id': 18,
        'name': 'Baking Powder',
        'density': 1029.05,
        'category': 'Leavening Agents'
    },
    {
        'id': 19,
        'name': 'Baking Soda',
        'density': 2200.00,
        'category': 'Leavening Agents'
    },
    {
        'id': 20,
        'name': 'Salt, fine table',
        'density': 1378.00,
        'category': 'Other Dry Ingredients'
    },
    {
        'id': 21,
        'name': 'Salt, granulated',
        'density': 1282.00,
        'category': 'Other Dry Ingredients'
    },
    {
        'id': 22,
        'name': 'Sugar, white',
        'density': 836.90,
        'category': 'Other Dry Ingredients'
    },
    {
        'id': 23,
        'name': 'Sugar, powdered',
        'density': 801.00,
        'category': 'Other Dry Ingredients'
    },
    {
        'id': 24,
        'name': 'Sugar, brown',
        'density': 845.35,
        'category': 'Other Dry Ingredients'
    },
    {
        'id': 25,
        'name': 'Cocca Powder',
        'density': 430.00,
        'category': 'Other Dry Ingredients'
    },
    {
        'id': 26,
        'name': 'Corn Starch',
        'density': 528.33,
        'category': 'Other Dry Ingredients'
    },
    {
        'id': 27,
        'name': 'Milk, powdered',
        'density': 449.00,
        'category': 'Other Dry Ingredients'
    }
]

export { ingredientOptions };