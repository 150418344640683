import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ isChecked, setIsChecked, checkedLabel, uncheckedLabel }) => {

  return (
    // <div>
    //     <input onChange={() => setIsChecked(!isChecked)} value={isChecked} type="checkbox" id="switch" /><label htmlFor="switch">Toggle</label>        
    //     <span>{isChecked ? leftLabel : rightLabel}</span>
    // </div>
    <>
        <label className="toggle">
            <input onChange={() => setIsChecked(!isChecked)} value={isChecked} className="toggle-checkbox" type="checkbox" />
            <div className="toggle-switch"></div>
            <span className="toggle-label">{isChecked ? checkedLabel : uncheckedLabel}</span>
        </label>
    </>
  )
}
  
export default ToggleSwitch;