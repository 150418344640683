import './CalculatorForm.css'

import React from 'react';
import ToggleSwitch from '../ToggleSwitch'

import { useState } from 'react'
import { ingredientOptions } from '../../data/IngredientOptions';
import { DebounceInput } from 'react-debounce-input';
import { cup_to_l, tbsp_to_l, tsp_to_l, oz_to_g, ml_to_l, g_to_kg, lbs_to_g } from '../../data/Conversions';

export default function CalculatorForm() {

  const num_decimal_places = 3
  const input_debounce_ms = 750

  const [selectedIngredient, setSelectedIngredient] = useState(ingredientOptions[0])
  const [weightGrams, setWeight] = useState(0)
  const [volumeLiters, setVolume] = useState(0)
  const [metricUnits, setMetricUnits] = useState(true)

  let ingredentOptionGroups = [...new Set(ingredientOptions.map(ing => ing.category))];

  const onIngredientSelected = (e) => {
    let selectedId = Number(e.target.value)
    let ingredentObj = ingredientOptions.filter(ing => ing.id === selectedId)[0];
    setSelectedIngredient(ingredentObj)
    weightChanged(weightGrams)
  }

  const weightChanged = (new_weight_grams) => {
    setWeight(new_weight_grams)
    setVolume(new_weight_grams / selectedIngredient.density)
  }

  const volumeChanged = (new_volume_liters) => {
    setVolume(new_volume_liters)
    setWeight(new_volume_liters * selectedIngredient.density)
  }

  return (
    <form className='calc-form'>
      <ToggleSwitch checkedLabel='Metric' uncheckedLabel='Imperial' isChecked={metricUnits} setIsChecked={setMetricUnits}/>
        <label>
            <span>Ingredient:</span>
            <select onChange={onIngredientSelected} value={selectedIngredient.id} type="text">
                {ingredentOptionGroups.map(groupName => ( 
                  <optgroup label={groupName} key={groupName}>
                    {
                      ingredientOptions.filter(ing => ing.category === groupName).map(ing => {
                          return (<option key={ing.id} value={ing.id}>{ing.name}</option>)
                      })
                    }
                  </optgroup>
                  )
                )}
            </select>
        </label>
        <div className='parameter-inputs'>
          {metricUnits && (
            <>
              <label>
                  <span>Weight, kilograms (kg):</span>
                  <DebounceInput onChange={(e) => weightChanged(e.target.value / g_to_kg)} value={String((weightGrams * g_to_kg).toFixed(num_decimal_places).replace(/[.,]0+$/, ""))} type="number" debounceTimeout={input_debounce_ms}></DebounceInput>
              </label>
              <label>
                  <span>Weight, grams (g):</span>
                  <DebounceInput onChange={(e) => weightChanged(e.target.value)} value={Number(weightGrams).toFixed(num_decimal_places).replace(/[.,]0+$/, "")} type="number" debounceTimeout={input_debounce_ms}></DebounceInput>
              </label>
              <label>
                  <span>Volume, liters (L):</span>
                  <DebounceInput onChange={(e) => volumeChanged(e.target.value)} value={Number(volumeLiters).toFixed(num_decimal_places).replace(/[.,]0+$/, "")} type="number" debounceTimeout={input_debounce_ms}></DebounceInput>
              </label>
              <label>
                  <span>Volume, milliliters (mL):</span>
                  <DebounceInput onChange={(e) => volumeChanged(e.target.value * ml_to_l)} value={(volumeLiters / ml_to_l).toFixed(num_decimal_places).replace(/[.,]0+$/, "")} type="number" debounceTimeout={input_debounce_ms}></DebounceInput>
              </label>
            </>
          )
          }
          {!metricUnits && (
            <>
              <label>
                  <span>Weight, pounds (lbs):</span>
                  <DebounceInput onChange={(e) => weightChanged(e.target.value * lbs_to_g)} value={(weightGrams / lbs_to_g).toFixed(num_decimal_places).replace(/[.,]0+$/, "")} type="number" debounceTimeout={input_debounce_ms}></DebounceInput>
              </label>
              <label>
                  <span>Weight, ounces (oz):</span>
                  <DebounceInput onChange={(e) => weightChanged(e.target.value * oz_to_g)} value={(weightGrams / oz_to_g).toFixed(num_decimal_places).replace(/[.,]0+$/, "")} type="number" debounceTimeout={input_debounce_ms}></DebounceInput>
              </label>
              <label>
                  <span>Volume, cups:</span>
                  <DebounceInput onChange={(e) => volumeChanged(e.target.value * cup_to_l)} value={(volumeLiters / cup_to_l).toFixed(num_decimal_places).replace(/[.,]0+$/, "")} type="number" debounceTimeout={input_debounce_ms}></DebounceInput>
              </label>
              <label>
                  <span>Volume, tablespoons (tbsp):</span>
                  <DebounceInput onChange={(e) => volumeChanged(e.target.value * tbsp_to_l)} value={(volumeLiters / tbsp_to_l).toFixed(num_decimal_places).replace(/[.,]0+$/, "")} type="number" debounceTimeout={input_debounce_ms}></DebounceInput>
              </label>
              <label>
                  <span>Volume, teaspoons (tsp):</span>
                  <DebounceInput onChange={(e) => volumeChanged(e.target.value * tsp_to_l)} value={(volumeLiters / tsp_to_l).toFixed(num_decimal_places).replace(/[.,]0+$/, "")} type="number" debounceTimeout={input_debounce_ms}></DebounceInput>
              </label>
            </>
          )
          }
        </div>
    </form>
  )
}